<template>
  <div :class="['page-layout', { filled: filledLayout }]">
    <div :class="{ filled: filledHeader }">
      <slot name="header" v-bind="{ isCollapsed, toggleCollapsed }" />
    </div>
    <div
      :class="['page-content', { 'page-content--padded': hasContentPadding }]"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({ name: 'page-layout' });
</script>

<script setup lang="ts">
import { useSidebar } from '@/components/template/layout';

defineProps({
  hasContentPadding: {
    type: Boolean,
    default: false,
  },
  filledLayout: {
    type: Boolean,
    default: false,
  },
  filledHeader: {
    type: Boolean,
    default: false,
  },
});

const { isCollapsed, toggleCollapsed } = useSidebar();
</script>

<style lang="stylus" scoped>


.page-layout
  grid-template-rows auto minmax(0, 1fr)
  height 100%
  display grid
  overflow-y auto

.page-content
  @apply bg-gray-3

  &--padded
    padding 0 40px 20px 46px

.filled
  @apply bg-gray-3
</style>
