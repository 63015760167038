/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Defect,
    DefectFromJSON,
    DefectFromJSONTyped,
    DefectToJSON,
} from './Defect';
import {
    DefectDetailedAllOf,
    DefectDetailedAllOfFromJSON,
    DefectDetailedAllOfFromJSONTyped,
    DefectDetailedAllOfToJSON,
} from './DefectDetailedAllOf';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface DefectDetailed
 */
export interface DefectDetailed {
    /**
     * 
     * @type {string}
     * @memberof DefectDetailed
     */
    reasonId: string;
    /**
     * 
     * @type {string}
     * @memberof DefectDetailed
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof DefectDetailed
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DefectDetailed
     */
    skuId: number;
    /**
     * 
     * @type {number}
     * @memberof DefectDetailed
     */
    userId: number;
    /**
     * 
     * @type {User}
     * @memberof DefectDetailed
     */
    user: User;
    /**
     * 
     * @type {boolean}
     * @memberof DefectDetailed
     */
    isSent: boolean;
    /**
     * 
     * @type {number}
     * @memberof DefectDetailed
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof DefectDetailed
     */
    updatedAt: number;
}

export function DefectDetailedFromJSON(json: any): DefectDetailed {
    return DefectDetailedFromJSONTyped(json, false);
}

export function DefectDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): DefectDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonId': json['reasonId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'id': json['id'],
        'skuId': json['skuId'],
        'userId': json['userId'],
        'user': UserFromJSON(json['user']),
        'isSent': json['isSent'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function DefectDetailedToJSON(value?: DefectDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonId': value.reasonId,
        'comment': value.comment,
        'id': value.id,
        'skuId': value.skuId,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'isSent': value.isSent,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}

