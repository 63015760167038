/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiPhotorequestsPhotoRequestIDPhotoGetRequest {
    photoRequestID: number;
    path: string;
    body?: Blob;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Фото берется из папки по параметру path
     * Получение оригинального фото из NC
     */
    async apiPhotorequestsPhotoRequestIDPhotoGetRaw(requestParameters: ApiPhotorequestsPhotoRequestIDPhotoGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling apiPhotorequestsPhotoRequestIDPhotoGet.');
        }

        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling apiPhotorequestsPhotoRequestIDPhotoGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.path !== undefined) {
            queryParameters['path'] = requestParameters.path;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'image/jpeg';

        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photo`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Фото берется из папки по параметру path
     * Получение оригинального фото из NC
     */
    async apiPhotorequestsPhotoRequestIDPhotoGet(requestParameters: ApiPhotorequestsPhotoRequestIDPhotoGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPhotorequestsPhotoRequestIDPhotoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
