/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddOrRemovePhotoRequestActor,
    AddOrRemovePhotoRequestActorFromJSON,
    AddOrRemovePhotoRequestActorToJSON,
    BulkDeletePhotoRequestRequest,
    BulkDeletePhotoRequestRequestFromJSON,
    BulkDeletePhotoRequestRequestToJSON,
    BulkDeletePhotoRequestResponse,
    BulkDeletePhotoRequestResponseFromJSON,
    BulkDeletePhotoRequestResponseToJSON,
    ChangePhotoRequestEmployeeID,
    ChangePhotoRequestEmployeeIDFromJSON,
    ChangePhotoRequestEmployeeIDToJSON,
    ChangePhotoRequestPhotoLink,
    ChangePhotoRequestPhotoLinkFromJSON,
    ChangePhotoRequestPhotoLinkToJSON,
    ChangePhotoRequestPhotoModelID,
    ChangePhotoRequestPhotoModelIDFromJSON,
    ChangePhotoRequestPhotoModelIDToJSON,
    ChangePhotoRequestStatus,
    ChangePhotoRequestStatusFromJSON,
    ChangePhotoRequestStatusToJSON,
    ChangeRequestStatusBulkRequest,
    ChangeRequestStatusBulkRequestFromJSON,
    ChangeRequestStatusBulkRequestToJSON,
    ChangeRequestStatusBulkResponse,
    ChangeRequestStatusBulkResponseFromJSON,
    ChangeRequestStatusBulkResponseToJSON,
    Defect,
    DefectFromJSON,
    DefectToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineObject1,
    InlineObject1FromJSON,
    InlineObject1ToJSON,
    InlineObject2,
    InlineObject2FromJSON,
    InlineObject2ToJSON,
    InlineObject3,
    InlineObject3FromJSON,
    InlineObject3ToJSON,
    PhotoDateResponse,
    PhotoDateResponseFromJSON,
    PhotoDateResponseToJSON,
    PhotoRequest,
    PhotoRequestFromJSON,
    PhotoRequestToJSON,
    PhotoRequestComment,
    PhotoRequestCommentFromJSON,
    PhotoRequestCommentToJSON,
    PhotoRequestDetailed,
    PhotoRequestDetailedFromJSON,
    PhotoRequestDetailedToJSON,
    PhotoRequestEventListResponse,
    PhotoRequestEventListResponseFromJSON,
    PhotoRequestEventListResponseToJSON,
    PhotoRequestItem,
    PhotoRequestItemFromJSON,
    PhotoRequestItemToJSON,
    PhotoRequestItemDefectReason,
    PhotoRequestItemDefectReasonFromJSON,
    PhotoRequestItemDefectReasonToJSON,
    PhotoRequestItemDefectReasonType,
    PhotoRequestItemDefectReasonTypeFromJSON,
    PhotoRequestItemDefectReasonTypeToJSON,
    PhotoRequestItemResponse,
    PhotoRequestItemResponseFromJSON,
    PhotoRequestItemResponseToJSON,
    PhotoRequestItemTemplate,
    PhotoRequestItemTemplateFromJSON,
    PhotoRequestItemTemplateToJSON,
    PhotoRequestListResponse,
    PhotoRequestListResponseFromJSON,
    PhotoRequestListResponseToJSON,
    PhotoRequestMovePhotoResult,
    PhotoRequestMovePhotoResultFromJSON,
    PhotoRequestMovePhotoResultToJSON,
    PhotoRequestStatus,
    PhotoRequestStatusFromJSON,
    PhotoRequestStatusToJSON,
    PhotoRequestTemplate,
    PhotoRequestTemplateFromJSON,
    PhotoRequestTemplateToJSON,
    PhotoRequestWithSkuID,
    PhotoRequestWithSkuIDFromJSON,
    PhotoRequestWithSkuIDToJSON,
    RefundPhotoRequestPaymentByItemIDsResponseError,
    RefundPhotoRequestPaymentByItemIDsResponseErrorFromJSON,
    RefundPhotoRequestPaymentByItemIDsResponseErrorToJSON,
    RefundPhotoRequestPaymentByItemIds,
    RefundPhotoRequestPaymentByItemIdsFromJSON,
    RefundPhotoRequestPaymentByItemIdsToJSON,
    RenderRequestsTemplate,
    RenderRequestsTemplateFromJSON,
    RenderRequestsTemplateToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeToJSON,
    SkuStatus,
    SkuStatusFromJSON,
    SkuStatusToJSON,
    SortPhotoRequestItems,
    SortPhotoRequestItemsFromJSON,
    SortPhotoRequestItemsToJSON,
    SortPhotoRequests,
    SortPhotoRequestsFromJSON,
    SortPhotoRequestsToJSON,
    UpdatePhotoRequest,
    UpdatePhotoRequestFromJSON,
    UpdatePhotoRequestToJSON,
    UploadedPhotoInfo,
    UploadedPhotoInfoFromJSON,
    UploadedPhotoInfoToJSON,
} from '../models';

export interface AddItemToPhotoRequestRequest {
    photoRequestID: number;
    photoRequestItemTemplate?: PhotoRequestItemTemplate;
}

export interface AddPhotoLinkAfterToPhotoRequestRequest {
    photoRequestID: number;
    changePhotoRequestPhotoLink?: ChangePhotoRequestPhotoLink;
}

export interface AddPhotoLinkBeforeToPhotoRequestRequest {
    photoRequestID: number;
    changePhotoRequestPhotoLink?: ChangePhotoRequestPhotoLink;
}

export interface AddPhotoLinkToPhotoRequestRequest {
    photoRequestID: number;
    changePhotoRequestPhotoLink?: ChangePhotoRequestPhotoLink;
}

export interface AddPhotoRequestActorRequest {
    photoRequestID: number;
    addOrRemovePhotoRequestActor?: AddOrRemovePhotoRequestActor;
}

export interface AddPhotoRequestCommentRequest {
    photoRequestID: number;
    inlineObject2?: InlineObject2;
}

export interface AddPhotoRequestItemPhotoDateRequest {
    itemID: number;
}

export interface ApiPhotorequestsPhotoRequestIDPhotoGetRequest {
    photoRequestID: number;
    path: string;
    body?: Blob;
}

export interface BulkDletePhotoRequestRequest {
    bulkDeletePhotoRequestRequest?: BulkDeletePhotoRequestRequest;
}

export interface ChangePhotoRequestItemReviewStatusRequest {
    itemID: number;
    defect?: Defect;
}

export interface ChangePhotoRequestItemsPaymentRateRequest {
    photoRequestID: number;
    inlineObject?: InlineObject;
}

export interface ChangePhotoRequestPhotoModelIDRequest {
    photoRequestID: number;
    changePhotoRequestPhotoModelID?: ChangePhotoRequestPhotoModelID;
}

export interface ChangePhotoRequestPhotographerIDRequest {
    photoRequestID: number;
    changePhotoRequestEmployeeID?: ChangePhotoRequestEmployeeID;
}

export interface ChangePhotoRequestProducerIDRequest {
    photoRequestID: number;
    changePhotoRequestEmployeeID?: ChangePhotoRequestEmployeeID;
}

export interface ChangePhotoRequestRetoucherIDRequest {
    photoRequestID: number;
    changePhotoRequestEmployeeID?: ChangePhotoRequestEmployeeID;
}

export interface ChangePhotoRequestStatusRequest {
    photoRequestID: number;
}

export interface ChangePhotoRequestStatusBulkRequest {
    changeRequestStatusBulkRequest?: ChangeRequestStatusBulkRequest;
}

export interface CreatePhotoRequestRequest {
    photoRequestTemplate?: PhotoRequestTemplate;
}

export interface DeletePhotoRequestRequest {
    photoRequestID: number;
}

export interface DeletePhotoRequestCommentRequest {
    commentID: number;
}

export interface DeletePhotoRequestItemRequest {
    itemID: number;
}

export interface DeletePhotoRequestItemDefectRequest {
    defectID: number;
}

export interface GetPhotoRequestItemsDefectReasonListRequest {
    types?: Array<PhotoRequestItemDefectReasonType>;
}

export interface GetPhotoRequestPrintViewRequest {
    photoRequestID: number;
}

export interface GetPhotoRequestsBySkuIDsRequest {
    skuIds: Array<number>;
}

export interface InternalChangePhotoRequestStatusRequest {
    photoRequestID: number;
    changePhotoRequestStatus?: ChangePhotoRequestStatus;
}

export interface InternalPhotoRequestDetailRequest {
    photoRequestID: number;
}

export interface InternalPhotoRequestItemsListRequest {
    photoRequestID: number;
    page: number;
    size: number;
    skuID?: number;
    status?: Array<SkuStatus>;
    shootingType?: Array<ShootingType>;
    shootingTypeEq?: Array<ShootingType>;
    includeDeleted?: boolean;
    q?: string;
    sort?: Array<SortPhotoRequestItems>;
}

export interface InternalRenderPhotoRequestsCreateRequest {
    renderRequestsTemplate?: RenderRequestsTemplate;
}

export interface PhotoRequestDetailRequest {
    photoRequestID: number;
}

export interface PhotoRequestHistoryRequest {
    photoRequestID: number;
    page: number;
    size: number;
}

export interface PhotoRequestItemsListRequest {
    photoRequestID: number;
    page: number;
    size: number;
    skuID?: number;
    status?: Array<SkuStatus>;
    shootingType?: Array<ShootingType>;
    shootingTypeEq?: Array<ShootingType>;
    q?: string;
    sort?: Array<SortPhotoRequestItems>;
}

export interface PhotoRequestMovePhotoResultRequest {
    photoRequestID: number;
}

export interface PhotoRequestUploadPhotoRequest {
    photoRequestID: number;
    body?: Blob;
}

export interface PhotoRequestUploadedPhotoInfoRequest {
    photoRequestID: number;
    path: string;
}

export interface PhotoRequestsListRequest {
    page: number;
    size: number;
    status?: PhotoRequestStatus;
    shootingType?: Array<ShootingType>;
    createdFrom?: number;
    createdTo?: number;
    updatedFrom?: number;
    updatedTo?: number;
    ids?: Array<number>;
    photoBoxIDs?: Array<number>;
    invoiceIDs?: Array<number>;
    hasItemDefects?: boolean;
    sort?: Array<SortPhotoRequests>;
    q?: string;
}

export interface PhotoRequestsListBySkuGroupIDsRequest {
    page: number;
    size: number;
    skuGroupIDs?: Array<number>;
}

export interface RefundPhotoRequestPaymentByItemIDsRequest {
    refundPhotoRequestPaymentByItemIds?: RefundPhotoRequestPaymentByItemIds;
}

export interface RemovePhotoRequestActorRequest {
    photoRequestID: number;
    addOrRemovePhotoRequestActor?: AddOrRemovePhotoRequestActor;
}

export interface StartUploadTaskForPhotoRequestRequest {
    photoRequestID: number;
}

export interface UpdatePhotoRequestRequest {
    photoRequestID: number;
    updatePhotoRequest: UpdatePhotoRequest;
}

export interface UpdatePhotoRequestCommentRequest {
    commentID: number;
    inlineObject3?: InlineObject3;
}

export interface UpdatePhotoRequestItemDefectRequest {
    defectID: number;
    inlineObject1: InlineObject1;
}

/**
 * 
 */
export class PhotoRequestApi extends runtime.BaseAPI {

    /**
     * Добавить элемент в фотозаявку
     */
    async addItemToPhotoRequestRaw(requestParameters: AddItemToPhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestItem>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling addItemToPhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/items`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhotoRequestItemTemplateToJSON(requestParameters.photoRequestItemTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestItemFromJSON(jsonValue));
    }

    /**
     * Добавить элемент в фотозаявку
     */
    async addItemToPhotoRequest(requestParameters: AddItemToPhotoRequestRequest, initOverrides?: RequestInit): Promise<PhotoRequestItem> {
        const response = await this.addItemToPhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить ссылку на фото после
     */
    async addPhotoLinkAfterToPhotoRequestRaw(requestParameters: AddPhotoLinkAfterToPhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling addPhotoLinkAfterToPhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photoLinkAfter`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestPhotoLinkToJSON(requestParameters.changePhotoRequestPhotoLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Обновить ссылку на фото после
     */
    async addPhotoLinkAfterToPhotoRequest(requestParameters: AddPhotoLinkAfterToPhotoRequestRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.addPhotoLinkAfterToPhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить ссылку на фото до
     */
    async addPhotoLinkBeforeToPhotoRequestRaw(requestParameters: AddPhotoLinkBeforeToPhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling addPhotoLinkBeforeToPhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photoLinkBefore`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestPhotoLinkToJSON(requestParameters.changePhotoRequestPhotoLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Обновить ссылку на фото до
     */
    async addPhotoLinkBeforeToPhotoRequest(requestParameters: AddPhotoLinkBeforeToPhotoRequestRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.addPhotoLinkBeforeToPhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить ссылку на фото
     */
    async addPhotoLinkToPhotoRequestRaw(requestParameters: AddPhotoLinkToPhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling addPhotoLinkToPhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photoLink`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestPhotoLinkToJSON(requestParameters.changePhotoRequestPhotoLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Обновить ссылку на фото
     */
    async addPhotoLinkToPhotoRequest(requestParameters: AddPhotoLinkToPhotoRequestRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.addPhotoLinkToPhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавить действующее лицо
     */
    async addPhotoRequestActorRaw(requestParameters: AddPhotoRequestActorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling addPhotoRequestActor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/actor`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemovePhotoRequestActorToJSON(requestParameters.addOrRemovePhotoRequestActor),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавить действующее лицо
     */
    async addPhotoRequestActor(requestParameters: AddPhotoRequestActorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.addPhotoRequestActorRaw(requestParameters, initOverrides);
    }

    /**
     * Добавить комментарий
     */
    async addPhotoRequestCommentRaw(requestParameters: AddPhotoRequestCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestComment>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling addPhotoRequestComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/comment`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject2ToJSON(requestParameters.inlineObject2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestCommentFromJSON(jsonValue));
    }

    /**
     * Добавить комментарий
     */
    async addPhotoRequestComment(requestParameters: AddPhotoRequestCommentRequest, initOverrides?: RequestInit): Promise<PhotoRequestComment> {
        const response = await this.addPhotoRequestCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Установить дату, когда сделано фото.
     */
    async addPhotoRequestItemPhotoDateRaw(requestParameters: AddPhotoRequestItemPhotoDateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoDateResponse>> {
        if (requestParameters.itemID === null || requestParameters.itemID === undefined) {
            throw new runtime.RequiredError('itemID','Required parameter requestParameters.itemID was null or undefined when calling addPhotoRequestItemPhotoDate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/items/{itemID}/photo-date`.replace(`{${"itemID"}}`, encodeURIComponent(String(requestParameters.itemID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoDateResponseFromJSON(jsonValue));
    }

    /**
     * Установить дату, когда сделано фото.
     */
    async addPhotoRequestItemPhotoDate(requestParameters: AddPhotoRequestItemPhotoDateRequest, initOverrides?: RequestInit): Promise<PhotoDateResponse> {
        const response = await this.addPhotoRequestItemPhotoDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Фото берется из папки по параметру path
     * Получение оригинального фото из NC
     */
    async apiPhotorequestsPhotoRequestIDPhotoGetRaw(requestParameters: ApiPhotorequestsPhotoRequestIDPhotoGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling apiPhotorequestsPhotoRequestIDPhotoGet.');
        }

        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling apiPhotorequestsPhotoRequestIDPhotoGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.path !== undefined) {
            queryParameters['path'] = requestParameters.path;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'image/jpeg';

        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photo`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Фото берется из папки по параметру path
     * Получение оригинального фото из NC
     */
    async apiPhotorequestsPhotoRequestIDPhotoGet(requestParameters: ApiPhotorequestsPhotoRequestIDPhotoGetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.apiPhotorequestsPhotoRequestIDPhotoGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Массовое удаление фото заявок
     */
    async bulkDletePhotoRequestRaw(requestParameters: BulkDletePhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BulkDeletePhotoRequestResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: BulkDeletePhotoRequestRequestToJSON(requestParameters.bulkDeletePhotoRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkDeletePhotoRequestResponseFromJSON(jsonValue));
    }

    /**
     * Массовое удаление фото заявок
     */
    async bulkDletePhotoRequest(requestParameters: BulkDletePhotoRequestRequest = {}, initOverrides?: RequestInit): Promise<BulkDeletePhotoRequestResponse> {
        const response = await this.bulkDletePhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить статус проверки фото у элемента заявки
     */
    async changePhotoRequestItemReviewStatusRaw(requestParameters: ChangePhotoRequestItemReviewStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestItem>> {
        if (requestParameters.itemID === null || requestParameters.itemID === undefined) {
            throw new runtime.RequiredError('itemID','Required parameter requestParameters.itemID was null or undefined when calling changePhotoRequestItemReviewStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/items/{itemID}/review-status`.replace(`{${"itemID"}}`, encodeURIComponent(String(requestParameters.itemID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DefectToJSON(requestParameters.defect),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestItemFromJSON(jsonValue));
    }

    /**
     * Изменить статус проверки фото у элемента заявки
     */
    async changePhotoRequestItemReviewStatus(requestParameters: ChangePhotoRequestItemReviewStatusRequest, initOverrides?: RequestInit): Promise<PhotoRequestItem> {
        const response = await this.changePhotoRequestItemReviewStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Обновить тариф айтемов
     */
    async changePhotoRequestItemsPaymentRateRaw(requestParameters: ChangePhotoRequestItemsPaymentRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling changePhotoRequestItemsPaymentRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/items/payment-rate`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновить тариф айтемов
     */
    async changePhotoRequestItemsPaymentRate(requestParameters: ChangePhotoRequestItemsPaymentRateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.changePhotoRequestItemsPaymentRateRaw(requestParameters, initOverrides);
    }

    /**
     * Изменить id фотомодели
     */
    async changePhotoRequestPhotoModelIDRaw(requestParameters: ChangePhotoRequestPhotoModelIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling changePhotoRequestPhotoModelID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photoModel`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestPhotoModelIDToJSON(requestParameters.changePhotoRequestPhotoModelID),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Изменить id фотомодели
     */
    async changePhotoRequestPhotoModelID(requestParameters: ChangePhotoRequestPhotoModelIDRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.changePhotoRequestPhotoModelIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить id предметного фотографа
     */
    async changePhotoRequestPhotographerIDRaw(requestParameters: ChangePhotoRequestPhotographerIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling changePhotoRequestPhotographerID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photographer`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestEmployeeIDToJSON(requestParameters.changePhotoRequestEmployeeID),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Изменить id предметного фотографа
     */
    async changePhotoRequestPhotographerID(requestParameters: ChangePhotoRequestPhotographerIDRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.changePhotoRequestPhotographerIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить id продюсера
     */
    async changePhotoRequestProducerIDRaw(requestParameters: ChangePhotoRequestProducerIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling changePhotoRequestProducerID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/producer`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestEmployeeIDToJSON(requestParameters.changePhotoRequestEmployeeID),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Изменить id продюсера
     */
    async changePhotoRequestProducerID(requestParameters: ChangePhotoRequestProducerIDRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.changePhotoRequestProducerIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить id ретушёра
     */
    async changePhotoRequestRetoucherIDRaw(requestParameters: ChangePhotoRequestRetoucherIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling changePhotoRequestRetoucherID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/retoucher`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestEmployeeIDToJSON(requestParameters.changePhotoRequestEmployeeID),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Изменить id ретушёра
     */
    async changePhotoRequestRetoucherID(requestParameters: ChangePhotoRequestRetoucherIDRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.changePhotoRequestRetoucherIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить статус заявки
     */
    async changePhotoRequestStatusRaw(requestParameters: ChangePhotoRequestStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling changePhotoRequestStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/status`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Изменить статус заявки
     */
    async changePhotoRequestStatus(requestParameters: ChangePhotoRequestStatusRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.changePhotoRequestStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить статус заявок
     */
    async changePhotoRequestStatusBulkRaw(requestParameters: ChangePhotoRequestStatusBulkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChangeRequestStatusBulkResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/bulk/status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRequestStatusBulkRequestToJSON(requestParameters.changeRequestStatusBulkRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeRequestStatusBulkResponseFromJSON(jsonValue));
    }

    /**
     * Изменить статус заявок
     */
    async changePhotoRequestStatusBulk(requestParameters: ChangePhotoRequestStatusBulkRequest = {}, initOverrides?: RequestInit): Promise<ChangeRequestStatusBulkResponse> {
        const response = await this.changePhotoRequestStatusBulkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создать заявку на съемку с фотостудии
     */
    async createPhotoRequestRaw(requestParameters: CreatePhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhotoRequestTemplateToJSON(requestParameters.photoRequestTemplate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Создать заявку на съемку с фотостудии
     */
    async createPhotoRequest(requestParameters: CreatePhotoRequestRequest = {}, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.createPhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удалить фото заявку
     */
    async deletePhotoRequestRaw(requestParameters: DeletePhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling deletePhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить фото заявку
     */
    async deletePhotoRequest(requestParameters: DeletePhotoRequestRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePhotoRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Удалить комментарий
     */
    async deletePhotoRequestCommentRaw(requestParameters: DeletePhotoRequestCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.commentID === null || requestParameters.commentID === undefined) {
            throw new runtime.RequiredError('commentID','Required parameter requestParameters.commentID was null or undefined when calling deletePhotoRequestComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/comment/{commentID}`.replace(`{${"commentID"}}`, encodeURIComponent(String(requestParameters.commentID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить комментарий
     */
    async deletePhotoRequestComment(requestParameters: DeletePhotoRequestCommentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePhotoRequestCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Удалить элемент фотозаявки
     */
    async deletePhotoRequestItemRaw(requestParameters: DeletePhotoRequestItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.itemID === null || requestParameters.itemID === undefined) {
            throw new runtime.RequiredError('itemID','Required parameter requestParameters.itemID was null or undefined when calling deletePhotoRequestItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/items/{itemID}`.replace(`{${"itemID"}}`, encodeURIComponent(String(requestParameters.itemID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить элемент фотозаявки
     */
    async deletePhotoRequestItem(requestParameters: DeletePhotoRequestItemRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePhotoRequestItemRaw(requestParameters, initOverrides);
    }

    /**
     * Удалить брак айтема
     */
    async deletePhotoRequestItemDefectRaw(requestParameters: DeletePhotoRequestItemDefectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.defectID === null || requestParameters.defectID === undefined) {
            throw new runtime.RequiredError('defectID','Required parameter requestParameters.defectID was null or undefined when calling deletePhotoRequestItemDefect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/items/defects/{defectID}`.replace(`{${"defectID"}}`, encodeURIComponent(String(requestParameters.defectID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить брак айтема
     */
    async deletePhotoRequestItemDefect(requestParameters: DeletePhotoRequestItemDefectRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deletePhotoRequestItemDefectRaw(requestParameters, initOverrides);
    }

    /**
     * Получение причин брака для айтемов
     */
    async getPhotoRequestItemsDefectReasonListRaw(requestParameters: GetPhotoRequestItemsDefectReasonListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PhotoRequestItemDefectReason>>> {
        const queryParameters: any = {};

        if (requestParameters.types) {
            queryParameters['types'] = requestParameters.types;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/items/defects/reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhotoRequestItemDefectReasonFromJSON));
    }

    /**
     * Получение причин брака для айтемов
     */
    async getPhotoRequestItemsDefectReasonList(requestParameters: GetPhotoRequestItemsDefectReasonListRequest = {}, initOverrides?: RequestInit): Promise<Array<PhotoRequestItemDefectReason>> {
        const response = await this.getPhotoRequestItemsDefectReasonListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Распечатать фото заявку
     */
    async getPhotoRequestPrintViewRaw(requestParameters: GetPhotoRequestPrintViewRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling getPhotoRequestPrintView.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/print`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Распечатать фото заявку
     */
    async getPhotoRequestPrintView(requestParameters: GetPhotoRequestPrintViewRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getPhotoRequestPrintViewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список заявок по SkuIDs.
     */
    async getPhotoRequestsBySkuIDsRaw(requestParameters: GetPhotoRequestsBySkuIDsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PhotoRequestWithSkuID>>> {
        if (requestParameters.skuIds === null || requestParameters.skuIds === undefined) {
            throw new runtime.RequiredError('skuIds','Required parameter requestParameters.skuIds was null or undefined when calling getPhotoRequestsBySkuIDs.');
        }

        const queryParameters: any = {};

        if (requestParameters.skuIds) {
            queryParameters['skuIds'] = requestParameters.skuIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/by-sku`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhotoRequestWithSkuIDFromJSON));
    }

    /**
     * Получить список заявок по SkuIDs.
     */
    async getPhotoRequestsBySkuIDs(requestParameters: GetPhotoRequestsBySkuIDsRequest, initOverrides?: RequestInit): Promise<Array<PhotoRequestWithSkuID>> {
        const response = await this.getPhotoRequestsBySkuIDsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить статус заявки
     */
    async internalChangePhotoRequestStatusRaw(requestParameters: InternalChangePhotoRequestStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling internalChangePhotoRequestStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/photorequests/{photoRequestID}/status`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePhotoRequestStatusToJSON(requestParameters.changePhotoRequestStatus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Изменить статус заявки
     */
    async internalChangePhotoRequestStatus(requestParameters: InternalChangePhotoRequestStatusRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.internalChangePhotoRequestStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить заявку
     */
    async internalPhotoRequestDetailRaw(requestParameters: InternalPhotoRequestDetailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling internalPhotoRequestDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/photorequests/{photoRequestID}`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Получить заявку
     */
    async internalPhotoRequestDetail(requestParameters: InternalPhotoRequestDetailRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.internalPhotoRequestDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список товаров из заявки
     */
    async internalPhotoRequestItemsListRaw(requestParameters: InternalPhotoRequestItemsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestItemResponse>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling internalPhotoRequestItemsList.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling internalPhotoRequestItemsList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling internalPhotoRequestItemsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.skuID !== undefined) {
            queryParameters['skuID'] = requestParameters.skuID;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.shootingType) {
            queryParameters['shootingType'] = requestParameters.shootingType;
        }

        if (requestParameters.shootingTypeEq) {
            queryParameters['shootingTypeEq'] = requestParameters.shootingTypeEq;
        }

        if (requestParameters.includeDeleted !== undefined) {
            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/photorequests/{photoRequestID}/items`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestItemResponseFromJSON(jsonValue));
    }

    /**
     * Получить список товаров из заявки
     */
    async internalPhotoRequestItemsList(requestParameters: InternalPhotoRequestItemsListRequest, initOverrides?: RequestInit): Promise<PhotoRequestItemResponse> {
        const response = await this.internalPhotoRequestItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создать заявки на рендер
     */
    async internalRenderPhotoRequestsCreateRaw(requestParameters: InternalRenderPhotoRequestsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/photorequests/render`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenderRequestsTemplateToJSON(requestParameters.renderRequestsTemplate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Создать заявки на рендер
     */
    async internalRenderPhotoRequestsCreate(requestParameters: InternalRenderPhotoRequestsCreateRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.internalRenderPhotoRequestsCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Получить заявку
     */
    async photoRequestDetailRaw(requestParameters: PhotoRequestDetailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestDetailed>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestDetailedFromJSON(jsonValue));
    }

    /**
     * Получить заявку
     */
    async photoRequestDetail(requestParameters: PhotoRequestDetailRequest, initOverrides?: RequestInit): Promise<PhotoRequestDetailed> {
        const response = await this.photoRequestDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить историю по заявке на фотостудию
     */
    async photoRequestHistoryRaw(requestParameters: PhotoRequestHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestEventListResponse>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestHistory.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling photoRequestHistory.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling photoRequestHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/history`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestEventListResponseFromJSON(jsonValue));
    }

    /**
     * Получить историю по заявке на фотостудию
     */
    async photoRequestHistory(requestParameters: PhotoRequestHistoryRequest, initOverrides?: RequestInit): Promise<PhotoRequestEventListResponse> {
        const response = await this.photoRequestHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список товаров из заявки на фотостудию
     */
    async photoRequestItemsListRaw(requestParameters: PhotoRequestItemsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestItemResponse>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestItemsList.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling photoRequestItemsList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling photoRequestItemsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.skuID !== undefined) {
            queryParameters['skuID'] = requestParameters.skuID;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.shootingType) {
            queryParameters['shootingType'] = requestParameters.shootingType;
        }

        if (requestParameters.shootingTypeEq) {
            queryParameters['shootingTypeEq'] = requestParameters.shootingTypeEq;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/items`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestItemResponseFromJSON(jsonValue));
    }

    /**
     * Получить список товаров из заявки на фотостудию
     */
    async photoRequestItemsList(requestParameters: PhotoRequestItemsListRequest, initOverrides?: RequestInit): Promise<PhotoRequestItemResponse> {
        const response = await this.photoRequestItemsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Берется последний результат по перемещеню фото
     * Получить результат по перемещению фото
     */
    async photoRequestMovePhotoResultRaw(requestParameters: PhotoRequestMovePhotoResultRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestMovePhotoResult>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestMovePhotoResult.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/move-photo`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestMovePhotoResultFromJSON(jsonValue));
    }

    /**
     * Берется последний результат по перемещеню фото
     * Получить результат по перемещению фото
     */
    async photoRequestMovePhotoResult(requestParameters: PhotoRequestMovePhotoResultRequest, initOverrides?: RequestInit): Promise<PhotoRequestMovePhotoResult> {
        const response = await this.photoRequestMovePhotoResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Папка в которую загружаются фото зависит от статуса заявки. Название файла и нужные папки берутся из заголовков.
     * Загрузка фото заявки
     */
    async photoRequestUploadPhotoRaw(requestParameters: PhotoRequestUploadPhotoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestUploadPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'image/jpeg';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photo`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Папка в которую загружаются фото зависит от статуса заявки. Название файла и нужные папки берутся из заголовков.
     * Загрузка фото заявки
     */
    async photoRequestUploadPhoto(requestParameters: PhotoRequestUploadPhotoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.photoRequestUploadPhotoRaw(requestParameters, initOverrides);
    }

    /**
     * Папка из которой берется информация
     * Получить информацию по загруженным фото
     */
    async photoRequestUploadedPhotoInfoRaw(requestParameters: PhotoRequestUploadedPhotoInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UploadedPhotoInfo>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling photoRequestUploadedPhotoInfo.');
        }

        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling photoRequestUploadedPhotoInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.path !== undefined) {
            queryParameters['path'] = requestParameters.path;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/photo/info`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadedPhotoInfoFromJSON(jsonValue));
    }

    /**
     * Папка из которой берется информация
     * Получить информацию по загруженным фото
     */
    async photoRequestUploadedPhotoInfo(requestParameters: PhotoRequestUploadedPhotoInfoRequest, initOverrides?: RequestInit): Promise<UploadedPhotoInfo> {
        const response = await this.photoRequestUploadedPhotoInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список заявок на фотостудию.
     */
    async photoRequestsListRaw(requestParameters: PhotoRequestsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling photoRequestsList.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling photoRequestsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.shootingType) {
            queryParameters['shootingType'] = requestParameters.shootingType;
        }

        if (requestParameters.createdFrom !== undefined) {
            queryParameters['createdFrom'] = requestParameters.createdFrom;
        }

        if (requestParameters.createdTo !== undefined) {
            queryParameters['createdTo'] = requestParameters.createdTo;
        }

        if (requestParameters.updatedFrom !== undefined) {
            queryParameters['updatedFrom'] = requestParameters.updatedFrom;
        }

        if (requestParameters.updatedTo !== undefined) {
            queryParameters['updatedTo'] = requestParameters.updatedTo;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.photoBoxIDs) {
            queryParameters['photoBoxIDs'] = requestParameters.photoBoxIDs;
        }

        if (requestParameters.invoiceIDs) {
            queryParameters['invoiceIDs'] = requestParameters.invoiceIDs;
        }

        if (requestParameters.hasItemDefects !== undefined) {
            queryParameters['hasItemDefects'] = requestParameters.hasItemDefects;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список заявок на фотостудию.
     */
    async photoRequestsList(requestParameters: PhotoRequestsListRequest, initOverrides?: RequestInit): Promise<PhotoRequestListResponse> {
        const response = await this.photoRequestsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список заявок на фотостудию по SkuGroupIDs.
     */
    async photoRequestsListBySkuGroupIDsRaw(requestParameters: PhotoRequestsListBySkuGroupIDsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling photoRequestsListBySkuGroupIDs.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling photoRequestsListBySkuGroupIDs.');
        }

        const queryParameters: any = {};

        if (requestParameters.skuGroupIDs) {
            queryParameters['skuGroupIDs'] = requestParameters.skuGroupIDs;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/bySkuGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список заявок на фотостудию по SkuGroupIDs.
     */
    async photoRequestsListBySkuGroupIDs(requestParameters: PhotoRequestsListBySkuGroupIDsRequest, initOverrides?: RequestInit): Promise<PhotoRequestListResponse> {
        const response = await this.photoRequestsListBySkuGroupIDsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Частичный вовзрат платежа по айтемам заявки
     */
    async refundPhotoRequestPaymentByItemIDsRaw(requestParameters: RefundPhotoRequestPaymentByItemIDsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RefundPhotoRequestPaymentByItemIDsResponseError>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/photorequests/refundPaymentByItemIDs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefundPhotoRequestPaymentByItemIdsToJSON(requestParameters.refundPhotoRequestPaymentByItemIds),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RefundPhotoRequestPaymentByItemIDsResponseErrorFromJSON));
    }

    /**
     * Частичный вовзрат платежа по айтемам заявки
     */
    async refundPhotoRequestPaymentByItemIDs(requestParameters: RefundPhotoRequestPaymentByItemIDsRequest = {}, initOverrides?: RequestInit): Promise<Array<RefundPhotoRequestPaymentByItemIDsResponseError>> {
        const response = await this.refundPhotoRequestPaymentByItemIDsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удалить действующее лицо
     */
    async removePhotoRequestActorRaw(requestParameters: RemovePhotoRequestActorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling removePhotoRequestActor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/actor`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrRemovePhotoRequestActorToJSON(requestParameters.addOrRemovePhotoRequestActor),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить действующее лицо
     */
    async removePhotoRequestActor(requestParameters: RemovePhotoRequestActorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.removePhotoRequestActorRaw(requestParameters, initOverrides);
    }

    /**
     * Запустить загрузку фоток для заявки
     */
    async startUploadTaskForPhotoRequestRaw(requestParameters: StartUploadTaskForPhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling startUploadTaskForPhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/{photoRequestID}/uploadTask`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Запустить загрузку фоток для заявки
     */
    async startUploadTaskForPhotoRequest(requestParameters: StartUploadTaskForPhotoRequestRequest, initOverrides?: RequestInit): Promise<void> {
        await this.startUploadTaskForPhotoRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Обновление заявки
     */
    async updatePhotoRequestRaw(requestParameters: UpdatePhotoRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequest>> {
        if (requestParameters.photoRequestID === null || requestParameters.photoRequestID === undefined) {
            throw new runtime.RequiredError('photoRequestID','Required parameter requestParameters.photoRequestID was null or undefined when calling updatePhotoRequest.');
        }

        if (requestParameters.updatePhotoRequest === null || requestParameters.updatePhotoRequest === undefined) {
            throw new runtime.RequiredError('updatePhotoRequest','Required parameter requestParameters.updatePhotoRequest was null or undefined when calling updatePhotoRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/photorequests/{photoRequestID}`.replace(`{${"photoRequestID"}}`, encodeURIComponent(String(requestParameters.photoRequestID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePhotoRequestToJSON(requestParameters.updatePhotoRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestFromJSON(jsonValue));
    }

    /**
     * Обновление заявки
     */
    async updatePhotoRequest(requestParameters: UpdatePhotoRequestRequest, initOverrides?: RequestInit): Promise<PhotoRequest> {
        const response = await this.updatePhotoRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Изменить комментарий
     */
    async updatePhotoRequestCommentRaw(requestParameters: UpdatePhotoRequestCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PhotoRequestComment>> {
        if (requestParameters.commentID === null || requestParameters.commentID === undefined) {
            throw new runtime.RequiredError('commentID','Required parameter requestParameters.commentID was null or undefined when calling updatePhotoRequestComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/comment/{commentID}`.replace(`{${"commentID"}}`, encodeURIComponent(String(requestParameters.commentID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject3ToJSON(requestParameters.inlineObject3),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhotoRequestCommentFromJSON(jsonValue));
    }

    /**
     * Изменить комментарий
     */
    async updatePhotoRequestComment(requestParameters: UpdatePhotoRequestCommentRequest, initOverrides?: RequestInit): Promise<PhotoRequestComment> {
        const response = await this.updatePhotoRequestCommentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Редактирование дефекта
     */
    async updatePhotoRequestItemDefectRaw(requestParameters: UpdatePhotoRequestItemDefectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Defect>> {
        if (requestParameters.defectID === null || requestParameters.defectID === undefined) {
            throw new runtime.RequiredError('defectID','Required parameter requestParameters.defectID was null or undefined when calling updatePhotoRequestItemDefect.');
        }

        if (requestParameters.inlineObject1 === null || requestParameters.inlineObject1 === undefined) {
            throw new runtime.RequiredError('inlineObject1','Required parameter requestParameters.inlineObject1 was null or undefined when calling updatePhotoRequestItemDefect.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/photorequests/items/defects/{defectID}`.replace(`{${"defectID"}}`, encodeURIComponent(String(requestParameters.defectID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObject1ToJSON(requestParameters.inlineObject1),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefectFromJSON(jsonValue));
    }

    /**
     * Редактирование дефекта
     */
    async updatePhotoRequestItemDefect(requestParameters: UpdatePhotoRequestItemDefectRequest, initOverrides?: RequestInit): Promise<Defect> {
        const response = await this.updatePhotoRequestItemDefectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
