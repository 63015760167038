import { useI18n } from 'vue-i18n';

import { EAccountRole } from '@/api';

interface INameFormaterArgs {
  firstName: string;
  lastName: string;
  role: EAccountRole;
}

export const fullName = ({ firstName, lastName }: INameFormaterArgs) =>
  `${firstName} ${lastName}`;

export const fullNameWithRole = ({
  firstName,
  lastName,
  role,
}: INameFormaterArgs) => {
  const { t } = useI18n();
  const roleLang = t(`modal.request.roles.${role}`);

  return `${firstName} ${lastName} (${roleLang})`;
};

export const shortName = ({ firstName, lastName }: INameFormaterArgs) =>
  lastName?.length ? `${firstName} ${lastName.substring(0, 1)}.` : firstName;
