<template>
  <button :class="variant" :disabled="isLoading" @click="handleClick">
    <div v-if="isLoading" class="loader">
      <circullar-loader />
    </div>
    <slot />
  </button>
</template>
<script lang="ts">
import { computed, defineComponent, reactive } from 'vue';

import CircullarLoader from '../circullar-loader/index.vue';
import { TButtonVariant } from './types';

interface IState {
  isError: boolean;
  isLoading: boolean;
}

export default defineComponent({
  name: 'base-button',
  components: {
    CircullarLoader,
  },
  props: {
    variant: {
      type: String as () => TButtonVariant,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const state = reactive<IState>({ isError: false, isLoading: false });

    const handleClick = async (event: MouseEvent) => {
      try {
        state.isError = false;
        state.isLoading = true;

        return await props.onClick(event);
      } catch (e) {
        state.isError = true;
        throw e;
      } finally {
        state.isLoading = false;
      }
    };

    const isLoading = computed(() => props.loading || state.isLoading);
    const isError = computed(() => state.isError || props.error);

    return { handleClick, isLoading, isError };
  },
});
</script>
<style lang="stylus" scoped>


button
  cursor pointer
  outline none
  transition background-color 0.2s ease, transform 0.2s ease
  display grid
  grid-auto-flow column
  align-items center
  grid-column-gap 4px
  box-sizing border-box
  position relative
  padding 9px 12px

  @apply rounded leading-short text-m font-medium

  &:active
    transform scale(0.98)
  &:disabled
    cursor default
    pointer-events none

.primary
  @apply bg-violet-6 text-white

  &:hover
    @apply text-gray-1
    background-color darken(#6935BD, 5)
  &:disabled
    background-color lighten(#6935BD, 20)

.secondary
  @apply bg-white text-violet-1 border border-violet-1 border-dashed

  &:hover
    @apply bg-white
  &:active
    background-color Violet(LT34)
  &:disabled
    background-color Violet(LT20)
    border-color Violet(LT15)

.text
  background-color transparent
  color Gray(DK8)
  &:hover
    background-color Gray(LT48)
    color Gray(DK28)
  &:active
    background-color Gray(LT40)
  &:disabled
    color Gray(LT16)

.text-blue
  background-color transparent
  color Violet(LT6)
  &:hover
    background-color transparent
    color Violet(DK5)
  &:active
    background-color transparent
    color Violet(DK9)
  &:disabled
    background-color transparent
    color Violet(LT15)

.text-light
  @apply bg-transparent text-white

  &:hover,
  &:active,
  &:disabled
    @apply bg-transparent text-white

.error
  background-color White()
  color Violet(DK39)
  &:hover
    background-color White()

.light
  background-color Violet(LT38)
  color Violet(DK9)
  &:hover
    background-color Violet(LT30)
  &:active
    background-color Violet(LT34)
  &:disabled
    background-color Violet(LT40)
    color Violet(LT24)

.icon
  border none
  padding 0
  background-color transparent
  fill Gray(DK8)
  cursor pointer
  border-radius 2px
  transition background-color 200ms ease
  &:hover
    background Gray(LT48)
  &:active
    background Gray(LT40)

.loader
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background-color White(A80)
  display flex
  align-items center
  justify-content center
</style>
