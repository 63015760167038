/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ShootingType {
    Unspecified = 'UNSPECIFIED',
    Detailed = 'DETAILED',
    Model = 'MODEL',
    Panoramic = 'PANORAMIC',
    Render = 'RENDER',
    Infographic = 'INFOGRAPHIC'
}

export function ShootingTypeFromJSON(json: any): ShootingType {
    return ShootingTypeFromJSONTyped(json, false);
}

export function ShootingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShootingType {
    return json as ShootingType;
}

export function ShootingTypeToJSON(value?: ShootingType | null): any {
    return value as any;
}

