/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Category,
    CategoryFromJSON,
    CategoryToJSON,
    CategoryListResponse,
    CategoryListResponseFromJSON,
    CategoryListResponseToJSON,
    CategoryReferencesResponse,
    CategoryReferencesResponseFromJSON,
    CategoryReferencesResponseToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    SyncCategory,
    SyncCategoryFromJSON,
    SyncCategoryToJSON,
    UpdateCategoryRequest,
    UpdateCategoryRequestFromJSON,
    UpdateCategoryRequestToJSON,
    UpdateCategoryShootingTypes,
    UpdateCategoryShootingTypesFromJSON,
    UpdateCategoryShootingTypesToJSON,
} from '../models';

export interface GetCategoriesRequest {
    page: number;
    size: number;
    ids?: Array<number>;
}

export interface GetCategoryReferencesRequest {
    categoryID: number;
}

export interface InternalGetCategoriesRequest {
    page: number;
    size: number;
    ids?: Array<number>;
}

export interface InternalGetCategoryRequest {
    categoryID: number;
}

export interface InternalUpdateCategoryRequest {
    categoryID: number;
    updateCategoryRequest?: UpdateCategoryRequest;
}

export interface InternalUpdateCategoryShootingTypeRequest {
    categoryID: number;
    updateCategoryShootingTypes?: UpdateCategoryShootingTypes;
}

export interface SyncCategoryRequest {
    syncCategory?: SyncCategory;
}

/**
 * 
 */
export class CategoryApi extends runtime.BaseAPI {

    /**
     * Получить список категорий
     */
    async getCategoriesRaw(requestParameters: GetCategoriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CategoryListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getCategories.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список категорий
     */
    async getCategories(requestParameters: GetCategoriesRequest, initOverrides?: RequestInit): Promise<CategoryListResponse> {
        const response = await this.getCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить референсы
     */
    async getCategoryReferencesRaw(requestParameters: GetCategoryReferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CategoryReferencesResponse>> {
        if (requestParameters.categoryID === null || requestParameters.categoryID === undefined) {
            throw new runtime.RequiredError('categoryID','Required parameter requestParameters.categoryID was null or undefined when calling getCategoryReferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/categories/{categoryID}/references`.replace(`{${"categoryID"}}`, encodeURIComponent(String(requestParameters.categoryID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryReferencesResponseFromJSON(jsonValue));
    }

    /**
     * Получить референсы
     */
    async getCategoryReferences(requestParameters: GetCategoryReferencesRequest, initOverrides?: RequestInit): Promise<CategoryReferencesResponse> {
        const response = await this.getCategoryReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список категорий
     */
    async internalGetCategoriesRaw(requestParameters: InternalGetCategoriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CategoryListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling internalGetCategories.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling internalGetCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список категорий
     */
    async internalGetCategories(requestParameters: InternalGetCategoriesRequest, initOverrides?: RequestInit): Promise<CategoryListResponse> {
        const response = await this.internalGetCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async internalGetCategoryRaw(requestParameters: InternalGetCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryID === null || requestParameters.categoryID === undefined) {
            throw new runtime.RequiredError('categoryID','Required parameter requestParameters.categoryID was null or undefined when calling internalGetCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/categories/{categoryID}`.replace(`{${"categoryID"}}`, encodeURIComponent(String(requestParameters.categoryID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async internalGetCategory(requestParameters: InternalGetCategoryRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.internalGetCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async internalUpdateCategoryRaw(requestParameters: InternalUpdateCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryID === null || requestParameters.categoryID === undefined) {
            throw new runtime.RequiredError('categoryID','Required parameter requestParameters.categoryID was null or undefined when calling internalUpdateCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/categories/{categoryID}`.replace(`{${"categoryID"}}`, encodeURIComponent(String(requestParameters.categoryID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCategoryRequestToJSON(requestParameters.updateCategoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async internalUpdateCategory(requestParameters: InternalUpdateCategoryRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.internalUpdateCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async internalUpdateCategoryShootingTypeRaw(requestParameters: InternalUpdateCategoryShootingTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        if (requestParameters.categoryID === null || requestParameters.categoryID === undefined) {
            throw new runtime.RequiredError('categoryID','Required parameter requestParameters.categoryID was null or undefined when calling internalUpdateCategoryShootingType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/categories/{categoryID}/shooting_types`.replace(`{${"categoryID"}}`, encodeURIComponent(String(requestParameters.categoryID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCategoryShootingTypesToJSON(requestParameters.updateCategoryShootingTypes),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async internalUpdateCategoryShootingType(requestParameters: InternalUpdateCategoryShootingTypeRequest, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.internalUpdateCategoryShootingTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async syncCategoryRaw(requestParameters: SyncCategoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Category>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/categories/sync`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncCategoryToJSON(requestParameters.syncCategory),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryFromJSON(jsonValue));
    }

    /**
     */
    async syncCategory(requestParameters: SyncCategoryRequest = {}, initOverrides?: RequestInit): Promise<Category> {
        const response = await this.syncCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
