export enum EIntroKeys {
  BOXES = 'boxes_intro_shown',
  NEW_BOX_TRANSER = 'new_box_transfer_intro_shown',
  ADD_BOX_TRANSER_ITEM = 'add_box_transer_item_intro_shown',
  MULTIPLY_BOX_TRANSFER = 'multiply_box_transer_intro_shown',
  CREATE_PHOTOREQUEST = 'create_photorequest_intro_shown',
  PHOTOREQUEST_CREATED = 'photorequest_created_intro_shown',
  PHOTOREQUEST_ITEM_ADDED = 'photorequest_item_added_intro_shown',
  PHOTOREQUEST_ADD_FROM_TABLE = 'photorequest_add_from_table_intro_shown',
  PHOTOREQUEST_ITEM_ADDED_FROM_TABLE = 'photorequest_item_added_from_table_intro_shown',
  INVOICE_MARK_DEFECT = 'invoice_mark_defect_intro_shown',
  INVOICE_ACTION = 'invoice_action_intro_shown',
  INVOICE_BULK_ACTION = 'invoice_bulk_action_intro_shown',
  INVOICE_CHANGE_DEFECT = 'invoice_change_defect_intro_shown',
  FIRST_LAUNCH_INTRO = 'first_launch_intro_shown',
  PHOTOGRAPHER_FLOW_GET_STARTED = 'photograpger_flow_get_started_shown',
  PHOTOGRAPHER_FLOW_START_PHOTOSHOOT = 'photograpger_flow_start_photoshoot_shown',
  PHOTOGRAPHER_FLOW_PHOTOGRAPHED = 'photograpger_flow_photographed_shown',
  PHOTOGRAPHER_FLOW_SEPARATE_STATUSES = 'photograpger_flow_separate_statuses_shown',
  PHOTOGRAPHER_FLOW_PANORAMIC_PHOTOSHOOT = 'photograpger_flow_panoramic_photoshoot_shown',
  PHOTOGRAPHER_FLOW_PANORAMIC_UPLOAD = 'photograpger_flow_panoramic_upload_shown',
  SKU_IMAGE_CAROUSEL = 'sku_image_carousel_shown',
  OPEN_REFERENCES_INTRO = 'open_references_intro_shown',
}

export enum EIntroPlacement {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
  LEFT_TOP = 'leftTop',
  LEFT_BOTTOM = 'leftBottom',
  RIGHT_TOP = 'rightTop',
  RIGHT_BOTTOM = 'rightBottom',
}

export type TTriggerMode = 'hover' | 'focus' | 'click' | 'contextmenu';

export interface INavigation {
  text: string;
  step: number;
}

export interface IStep {
  element: string;
  placement: string;
  fragment: string;
  popupWrapper?: boolean;
  arrowAtCenter?: boolean;
  trigger?: TTriggerMode;
  navigation?: INavigation[];
  hideNext?: boolean;
  hideControls?: boolean;
}

export interface IIntroOptions {
  showSkip?: boolean;
  skipLabel?: string;
  dependentIntros?: EIntroKeys[];
  delay: number;
  steps: IStep[];
  forceFirstLaunch?: boolean;
}

export const introOptions: Record<string, IIntroOptions> = {
  [EIntroKeys.BOXES]: {
    delay: 500,
    steps: [
      {
        element: '.Boxes',
        placement: EIntroPlacement.RIGHT,
        fragment: 'boxes',
        arrowAtCenter: true,
      },
    ],
  },
  [EIntroKeys.NEW_BOX_TRANSER]: {
    delay: 0,
    steps: [
      {
        element: '#newTransfer',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'new-box-transfer',
      },
    ],
  },
  [EIntroKeys.ADD_BOX_TRANSER_ITEM]: {
    delay: 0,
    steps: [
      {
        element: '#transferWrapper',
        placement: EIntroPlacement.BOTTOM_RIGHT,
        fragment: 'add-box-transfer-item',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.MULTIPLY_BOX_TRANSFER]: {
    delay: 0,
    steps: [
      {
        element: '#transferWrapper',
        placement: EIntroPlacement.BOTTOM_RIGHT,
        fragment: 'multiply-box-transfer',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.CREATE_PHOTOREQUEST]: {
    showSkip: true,
    dependentIntros: [
      EIntroKeys.PHOTOREQUEST_CREATED,
      EIntroKeys.PHOTOREQUEST_ITEM_ADDED,
      EIntroKeys.PHOTOREQUEST_ADD_FROM_TABLE,
      EIntroKeys.PHOTOREQUEST_ITEM_ADDED_FROM_TABLE,
    ],
    delay: 0,
    steps: [
      {
        element: '.create-btn',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'create-photorequest',
      },
    ],
  },
  [EIntroKeys.PHOTOREQUEST_CREATED]: {
    delay: 0,
    steps: [
      {
        element: '.photorequest-latest',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photorequest-created-1',
        hideControls: true,
        hideNext: true,
        navigation: [
          {
            text: 'add sku without attachment',
            step: 1,
          },
          {
            text: 'multiply photorequests',
            step: 2,
          },
        ],
      },
      {
        element: '.photorequest-latest',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photorequest-created-2',
        hideNext: true,
      },
      {
        element: '.photorequest-latest',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photorequest-created-3',
        hideNext: true,
      },
    ],
  },
  [EIntroKeys.PHOTOREQUEST_ITEM_ADDED]: {
    delay: 300,
    steps: [
      {
        element: '.select-link-current',
        placement: EIntroPlacement.LEFT,
        fragment: 'photorequest-item-added',
        trigger: 'hover',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.PHOTOREQUEST_ADD_FROM_TABLE]: {
    delay: 300,
    steps: [
      {
        element: '.table-photorequest .ant-dropdown',
        placement: EIntroPlacement.LEFT,
        fragment: 'photorequest-add-from-table',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.PHOTOREQUEST_ITEM_ADDED_FROM_TABLE]: {
    delay: 100,
    steps: [
      {
        element: '.select-link-current',
        placement: EIntroPlacement.LEFT,
        fragment: 'photorequest-item-added-from-table',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.INVOICE_MARK_DEFECT]: {
    showSkip: true,
    dependentIntros: [
      EIntroKeys.INVOICE_ACTION,
      EIntroKeys.INVOICE_BULK_ACTION,
      EIntroKeys.INVOICE_CHANGE_DEFECT,
    ],
    delay: 0,
    steps: [
      {
        element: '.edit-button',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'invoice-mark-defect',
      },
    ],
  },
  [EIntroKeys.INVOICE_ACTION]: {
    delay: 0,
    steps: [
      {
        element: '.ellipsis-icon',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'invoice-action',
      },
    ],
  },
  [EIntroKeys.INVOICE_BULK_ACTION]: {
    delay: 0,
    steps: [
      {
        element: '.ant-table-row .ant-checkbox',
        placement: EIntroPlacement.BOTTOM_RIGHT,
        fragment: 'invoice-bulk-action',
      },
    ],
  },
  [EIntroKeys.INVOICE_CHANGE_DEFECT]: {
    delay: 0,
    steps: [
      {
        element: '.reject-panel-set-defect',
        placement: EIntroPlacement.TOP,
        fragment: 'invoice-change-defect',
      },
    ],
  },
  [EIntroKeys.FIRST_LAUNCH_INTRO]: {
    delay: 0,
    showSkip: true,
    forceFirstLaunch: true,
    steps: [
      {
        element: '.Invoices',
        placement: EIntroPlacement.RIGHT,
        fragment: 'first-launch-1',
        arrowAtCenter: true,
      },
      {
        element: '.Requests',
        placement: EIntroPlacement.RIGHT,
        fragment: 'first-launch-2',
        arrowAtCenter: true,
      },
      {
        element: '.Products',
        placement: EIntroPlacement.RIGHT,
        fragment: 'first-launch-3',
        arrowAtCenter: true,
      },
      {
        element: '.Boxes',
        placement: EIntroPlacement.RIGHT,
        fragment: 'first-launch-4',
        arrowAtCenter: true,
      },
    ],
  },
  [EIntroKeys.PHOTOGRAPHER_FLOW_GET_STARTED]: {
    delay: 0,
    showSkip: true,
    skipLabel: 'feature intro.fragments.flow get started.skipLabel',
    steps: [
      {
        element: '[data-intro="flow-start"]',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photographer-flow-get-started',
        popupWrapper: true,
        arrowAtCenter: true,
      },
    ],
    dependentIntros: [
      EIntroKeys.PHOTOGRAPHER_FLOW_START_PHOTOSHOOT,
      EIntroKeys.PHOTOGRAPHER_FLOW_PHOTOGRAPHED,
      EIntroKeys.PHOTOGRAPHER_FLOW_PANORAMIC_PHOTOSHOOT,
      EIntroKeys.PHOTOGRAPHER_FLOW_SEPARATE_STATUSES,
      EIntroKeys.PHOTOGRAPHER_FLOW_PANORAMIC_UPLOAD,
    ],
  },
  [EIntroKeys.PHOTOGRAPHER_FLOW_START_PHOTOSHOOT]: {
    delay: 400,
    steps: [
      {
        element: '[data-intro="start-photoshoot"]',
        placement: EIntroPlacement.TOP,
        fragment: 'photographer-flow-start-photoshoot',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.PHOTOGRAPHER_FLOW_PHOTOGRAPHED]: {
    delay: 0,
    steps: [
      {
        element: '.skus-table-row',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photographer-flow-photographed',
        popupWrapper: true,
      },
      {
        element: '#detailed-sources-wrapper',
        placement: EIntroPlacement.TOP,
        fragment: 'photographer-flow-photo-upload',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.PHOTOGRAPHER_FLOW_SEPARATE_STATUSES]: {
    delay: 300,
    steps: [
      {
        element: '#status-btn-wrapper',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photographer-flow-separate-statuses',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.PHOTOGRAPHER_FLOW_PANORAMIC_PHOTOSHOOT]: {
    delay: 600,
    steps: [
      {
        element: '#status-btn-wrapper',
        placement: EIntroPlacement.BOTTOM_LEFT,
        fragment: 'photographer-flow-panoramic-photoshoot',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.PHOTOGRAPHER_FLOW_PANORAMIC_UPLOAD]: {
    delay: 600,
    steps: [
      {
        element: '#panoramic-sources-wrapper',
        placement: EIntroPlacement.BOTTOM,
        fragment: 'photographer-flow-photo-upload-panoramic',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.SKU_IMAGE_CAROUSEL]: {
    delay: 300,
    steps: [
      {
        element: '.sku-modal-image-wrapper',
        placement: EIntroPlacement.TOP,
        fragment: 'sku-image-carousel',
        popupWrapper: true,
      },
    ],
  },
  [EIntroKeys.OPEN_REFERENCES_INTRO]: {
    delay: 0,
    steps: [
      {
        element: '.open-references',
        placement: EIntroPlacement.LEFT,
        fragment: 'open-references',
        popupWrapper: true,
      },
    ],
  },
};
