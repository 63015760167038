/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Роль пользователя
 * @export
 * @enum {string}
 */
export enum UserRole {
    Unspecified = 'UNSPECIFIED',
    System = 'SYSTEM',
    Admin = 'ADMIN',
    IssuingEditor = 'ISSUING_EDITOR',
    PhotoStudioAdmin = 'PHOTO_STUDIO_ADMIN',
    PhotoStudioTurnoverWorker = 'PHOTO_STUDIO_TURNOVER_WORKER',
    PhotoStudioProducer = 'PHOTO_STUDIO_PRODUCER',
    PhotoStudioPhotographer = 'PHOTO_STUDIO_PHOTOGRAPHER',
    PhotoStudioRetoucher = 'PHOTO_STUDIO_RETOUCHER',
    PhotoStudioVisagiste = 'PHOTO_STUDIO_VISAGISTE',
    PhotoStudioAssistant = 'PHOTO_STUDIO_ASSISTANT',
    PhotoStudioSupportSpecialist = 'PHOTO_STUDIO_SUPPORT_SPECIALIST',
    PackingAdmin = 'PACKING_ADMIN',
    PackingBriefer = 'PACKING_BRIEFER',
    PackingEmployee = 'PACKING_EMPLOYEE',
    PackingManager = 'PACKING_MANAGER',
    PackingReserve = 'PACKING_RESERVE',
    PackingSupport = 'PACKING_SUPPORT',
    WarehouseAdmin = 'WAREHOUSE_ADMIN'
}

export function UserRoleFromJSON(json: any): UserRole {
    return UserRoleFromJSONTyped(json, false);
}

export function UserRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRole {
    return json as UserRole;
}

export function UserRoleToJSON(value?: UserRole | null): any {
    return value as any;
}

