/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePhotoRequest
 */
export interface UpdatePhotoRequest {
    /**
     * Идентификатор загрузки фотографий
     * @type {boolean}
     * @memberof UpdatePhotoRequest
     */
    uploadPhoto?: boolean;
}

export function UpdatePhotoRequestFromJSON(json: any): UpdatePhotoRequest {
    return UpdatePhotoRequestFromJSONTyped(json, false);
}

export function UpdatePhotoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePhotoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uploadPhoto': !exists(json, 'uploadPhoto') ? undefined : json['uploadPhoto'],
    };
}

export function UpdatePhotoRequestToJSON(value?: UpdatePhotoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uploadPhoto': value.uploadPhoto,
    };
}

