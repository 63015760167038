/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequest,
    PhotoRequestFromJSON,
    PhotoRequestFromJSONTyped,
    PhotoRequestToJSON,
} from './PhotoRequest';

/**
 * 
 * @export
 * @interface PhotoRequestWithSkuID
 */
export interface PhotoRequestWithSkuID {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestWithSkuID
     */
    skuId: number;
    /**
     * 
     * @type {PhotoRequest}
     * @memberof PhotoRequestWithSkuID
     */
    request: PhotoRequest;
}

export function PhotoRequestWithSkuIDFromJSON(json: any): PhotoRequestWithSkuID {
    return PhotoRequestWithSkuIDFromJSONTyped(json, false);
}

export function PhotoRequestWithSkuIDFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestWithSkuID {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skuId': json['skuId'],
        'request': PhotoRequestFromJSON(json['request']),
    };
}

export function PhotoRequestWithSkuIDToJSON(value?: PhotoRequestWithSkuID | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skuId': value.skuId,
        'request': PhotoRequestToJSON(value.request),
    };
}

