/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './UserRole';

/**
 * 
 * @export
 * @interface Actor
 */
export interface Actor {
    /**
     * Имя действующего лица
     * @type {string}
     * @memberof Actor
     */
    firstName: string;
    /**
     * Фамилия действующего лица
     * @type {string}
     * @memberof Actor
     */
    lastName: string;
    /**
     * 
     * @type {UserRole}
     * @memberof Actor
     */
    role?: UserRole;
}

export function ActorFromJSON(json: any): Actor {
    return ActorFromJSONTyped(json, false);
}

export function ActorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Actor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'role': !exists(json, 'role') ? undefined : UserRoleFromJSON(json['role']),
    };
}

export function ActorToJSON(value?: Actor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'role': UserRoleToJSON(value.role),
    };
}

