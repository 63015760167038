/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface UpdateCategoryShootingTypes
 */
export interface UpdateCategoryShootingTypes {
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof UpdateCategoryShootingTypes
     */
    shootingTypes: Array<ShootingType>;
}

export function UpdateCategoryShootingTypesFromJSON(json: any): UpdateCategoryShootingTypes {
    return UpdateCategoryShootingTypesFromJSONTyped(json, false);
}

export function UpdateCategoryShootingTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCategoryShootingTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
    };
}

export function UpdateCategoryShootingTypesToJSON(value?: UpdateCategoryShootingTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
    };
}

