/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    PhotoRequestItemDefect,
    PhotoRequestItemDefectFromJSON,
    PhotoRequestItemDefectFromJSONTyped,
    PhotoRequestItemDefectToJSON,
} from './PhotoRequestItemDefect';
import {
    PhotoRequestItemReviewStatus,
    PhotoRequestItemReviewStatusFromJSON,
    PhotoRequestItemReviewStatusFromJSONTyped,
    PhotoRequestItemReviewStatusToJSON,
} from './PhotoRequestItemReviewStatus';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';
import {
    Sku,
    SkuFromJSON,
    SkuFromJSONTyped,
    SkuToJSON,
} from './Sku';

/**
 * 
 * @export
 * @interface PhotoRequestItem
 */
export interface PhotoRequestItem {
    /**
     * Идентификтор элемента заявки
     * @type {number}
     * @memberof PhotoRequestItem
     */
    id: number;
    /**
     * Идентификтор родительского айтема
     * @type {number}
     * @memberof PhotoRequestItem
     */
    parentId?: number;
    /**
     * Список типов фотосъемок
     * @type {Array<ShootingType>}
     * @memberof PhotoRequestItem
     */
    shootingTypes: Array<ShootingType>;
    /**
     * 
     * @type {Sku}
     * @memberof PhotoRequestItem
     */
    sku: Sku;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestItem
     */
    productId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PhotoRequestItem
     */
    photoDates: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PhotoRequestItem
     */
    areShootingTypesChanged: boolean;
    /**
     * 
     * @type {PhotoRequestItemReviewStatus}
     * @memberof PhotoRequestItem
     */
    reviewStatus: PhotoRequestItemReviewStatus;
    /**
     * 
     * @type {PaymentRate}
     * @memberof PhotoRequestItem
     */
    paymentRate: PaymentRate;
    /**
     * 
     * @type {Array<PhotoRequestItemDefect>}
     * @memberof PhotoRequestItem
     */
    defects?: Array<PhotoRequestItemDefect>;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestItem
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestItem
     */
    updatedAt: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestItem
     */
    deletedAt?: number;
}

export function PhotoRequestItemFromJSON(json: any): PhotoRequestItem {
    return PhotoRequestItemFromJSONTyped(json, false);
}

export function PhotoRequestItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'sku': SkuFromJSON(json['sku']),
        'productId': json['productId'],
        'photoDates': json['photoDates'],
        'areShootingTypesChanged': json['areShootingTypesChanged'],
        'reviewStatus': PhotoRequestItemReviewStatusFromJSON(json['reviewStatus']),
        'paymentRate': PaymentRateFromJSON(json['paymentRate']),
        'defects': !exists(json, 'defects') ? undefined : ((json['defects'] as Array<any>).map(PhotoRequestItemDefectFromJSON)),
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : json['deletedAt'],
    };
}

export function PhotoRequestItemToJSON(value?: PhotoRequestItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'sku': SkuToJSON(value.sku),
        'productId': value.productId,
        'photoDates': value.photoDates,
        'areShootingTypesChanged': value.areShootingTypesChanged,
        'reviewStatus': PhotoRequestItemReviewStatusToJSON(value.reviewStatus),
        'paymentRate': PaymentRateToJSON(value.paymentRate),
        'defects': value.defects === undefined ? undefined : ((value.defects as Array<any>).map(PhotoRequestItemDefectToJSON)),
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'deletedAt': value.deletedAt,
    };
}

