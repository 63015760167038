/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CategoryReference,
    CategoryReferenceFromJSON,
    CategoryReferenceFromJSONTyped,
    CategoryReferenceToJSON,
} from './CategoryReference';

/**
 * 
 * @export
 * @interface CategoryReferencesResponse
 */
export interface CategoryReferencesResponse {
    /**
     * 
     * @type {string}
     * @memberof CategoryReferencesResponse
     */
    name: string;
    /**
     * 
     * @type {Array<CategoryReference>}
     * @memberof CategoryReferencesResponse
     */
    references: Array<CategoryReference>;
}

export function CategoryReferencesResponseFromJSON(json: any): CategoryReferencesResponse {
    return CategoryReferencesResponseFromJSONTyped(json, false);
}

export function CategoryReferencesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryReferencesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'references': ((json['references'] as Array<any>).map(CategoryReferenceFromJSON)),
    };
}

export function CategoryReferencesResponseToJSON(value?: CategoryReferencesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'references': ((value.references as Array<any>).map(CategoryReferenceToJSON)),
    };
}

