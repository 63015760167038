/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePhotoRequestEmployeeID
 */
export interface ChangePhotoRequestEmployeeID {
    /**
     * 
     * @type {number}
     * @memberof ChangePhotoRequestEmployeeID
     */
    id: number | null;
}

export function ChangePhotoRequestEmployeeIDFromJSON(json: any): ChangePhotoRequestEmployeeID {
    return ChangePhotoRequestEmployeeIDFromJSONTyped(json, false);
}

export function ChangePhotoRequestEmployeeIDFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePhotoRequestEmployeeID {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
    };
}

export function ChangePhotoRequestEmployeeIDToJSON(value?: ChangePhotoRequestEmployeeID | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

