<template>
  <div class="notification-container" @mouseenter="pause" @mouseleave="resume">
    <slot>
      <div class="notification-content">
        <component
          :is="iconComponent"
          v-if="iconComponent"
          :class="`notification-icon ant-notification-notice-icon-${type}`"
        />

        <div v-if="message" class="notification-message">
          {{ message }}
        </div>
      </div>
      <div v-if="description" class="font-inter ml-10 mt-1">
        {{ description }}
      </div>
      <div v-if="button" class="ml-10 mt-4">
        <ui-button type="primary" @click="button.callback">
          {{ button.text }}
        </ui-button>
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons-vue';
import { useInterval, whenever } from '@vueuse/shared';
import { computed, VNode } from 'vue';

import { NotificationButtonOption } from '@/features/Notifications/index';
import { BaseButton, UiButton } from '@/ui';

const SECOND = 1000;
const PER_SECOND = 20;
const INTERVAL = SECOND / PER_SECOND;

interface Props {
  duration: number;
  description?: string;
  message?: string;
  icon: VNode | (() => VNode) | undefined;
  type?: 'success' | 'info' | 'warning' | 'error';
  button?: NotificationButtonOption;
}

interface Emits {
  (e: 'close'): void;
}

const typeToIcon = {
  success: CheckCircleFilled,
  info: InfoCircleFilled,
  warning: ExclamationCircleFilled,
  error: CloseCircleFilled,
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const {
  counter: elapsed,
  pause,
  resume,
} = useInterval(INTERVAL, { controls: true });

const left = computed(() => props.duration - elapsed.value / PER_SECOND);
const done = computed(() => left.value <= 0);

const iconComponent = computed(() => {
  if (props.icon) return props.icon;

  return props.type && props.type in typeToIcon ? typeToIcon[props.type] : null;
});

whenever(done, () => emit('close'));
</script>

<style lang="less">
.ant-notification {
  z-index: 10000;
}

.ant-notification-notice {
  min-width: 400px;
}

.ant-notification-notice-message {
  display: none;
}

.ant-notification-notice-description {
  margin-left: 0 !important;
}

.notification-container {
  position: relative;

  .notification-content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  .notification-message {
    font-size: 16px;
    margin-right: 20px;
  }

  .notification-icon {
    font-size: 24px;
  }

  .notification-progress.ant-progress {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    .ant-progress-bg {
      transition-duration: 0.05s;
    }
  }

  .ant-progress-outer {
    display: flex;
  }
}
</style>
