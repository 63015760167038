/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequestComment,
    PhotoRequestCommentFromJSON,
    PhotoRequestCommentFromJSONTyped,
    PhotoRequestCommentToJSON,
} from './PhotoRequestComment';
import {
    PhotoRequestCommentDeleted,
    PhotoRequestCommentDeletedFromJSON,
    PhotoRequestCommentDeletedFromJSONTyped,
    PhotoRequestCommentDeletedToJSON,
} from './PhotoRequestCommentDeleted';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface PhotoRequestCommentListItem
 */
export interface PhotoRequestCommentListItem {
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestCommentListItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestCommentListItem
     */
    userId: number;
    /**
     * 
     * @type {User}
     * @memberof PhotoRequestCommentListItem
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestCommentListItem
     */
    comment: string;
    /**
     * 
     * @type {boolean}
     * @memberof PhotoRequestCommentListItem
     */
    edited: boolean;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestCommentListItem
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestCommentListItem
     */
    updated: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestCommentListItem
     */
    deleted: number;
}

export function PhotoRequestCommentListItemFromJSON(json: any): PhotoRequestCommentListItem {
    return PhotoRequestCommentListItemFromJSONTyped(json, false);
}

export function PhotoRequestCommentListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestCommentListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'user': UserFromJSON(json['user']),
        'comment': json['comment'],
        'edited': json['edited'],
        'created': json['created'],
        'updated': json['updated'],
        'deleted': json['deleted'],
    };
}

export function PhotoRequestCommentListItemToJSON(value?: PhotoRequestCommentListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'comment': value.comment,
        'edited': value.edited,
        'created': value.created,
        'updated': value.updated,
        'deleted': value.deleted,
    };
}

