/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddOrRemovePhotoRequestActorData,
    AddOrRemovePhotoRequestActorDataFromJSON,
    AddOrRemovePhotoRequestActorDataFromJSONTyped,
    AddOrRemovePhotoRequestActorDataToJSON,
} from './AddOrRemovePhotoRequestActorData';
import {
    ChangePhotoRequestItemDefectCommentData,
    ChangePhotoRequestItemDefectCommentDataFromJSON,
    ChangePhotoRequestItemDefectCommentDataFromJSONTyped,
    ChangePhotoRequestItemDefectCommentDataToJSON,
} from './ChangePhotoRequestItemDefectCommentData';
import {
    ChangePhotoRequestItemPaymentRateData,
    ChangePhotoRequestItemPaymentRateDataFromJSON,
    ChangePhotoRequestItemPaymentRateDataFromJSONTyped,
    ChangePhotoRequestItemPaymentRateDataToJSON,
} from './ChangePhotoRequestItemPaymentRateData';
import {
    ChangePhotoRequestItemReviewStatusData,
    ChangePhotoRequestItemReviewStatusDataFromJSON,
    ChangePhotoRequestItemReviewStatusDataFromJSONTyped,
    ChangePhotoRequestItemReviewStatusDataToJSON,
} from './ChangePhotoRequestItemReviewStatusData';
import {
    CreateNewPhotoRequestData,
    CreateNewPhotoRequestDataFromJSON,
    CreateNewPhotoRequestDataFromJSONTyped,
    CreateNewPhotoRequestDataToJSON,
} from './CreateNewPhotoRequestData';
import {
    CreatedPhotoRequestFromAnotherData,
    CreatedPhotoRequestFromAnotherDataFromJSON,
    CreatedPhotoRequestFromAnotherDataFromJSONTyped,
    CreatedPhotoRequestFromAnotherDataToJSON,
} from './CreatedPhotoRequestFromAnotherData';
import {
    DeletePhotoRequestItemData,
    DeletePhotoRequestItemDataFromJSON,
    DeletePhotoRequestItemDataFromJSONTyped,
    DeletePhotoRequestItemDataToJSON,
} from './DeletePhotoRequestItemData';
import {
    PaymentRate,
    PaymentRateFromJSON,
    PaymentRateFromJSONTyped,
    PaymentRateToJSON,
} from './PaymentRate';
import {
    PhotoRequestActorAdditionOrRemoval,
    PhotoRequestActorAdditionOrRemovalFromJSON,
    PhotoRequestActorAdditionOrRemovalFromJSONTyped,
    PhotoRequestActorAdditionOrRemovalToJSON,
} from './PhotoRequestActorAdditionOrRemoval';
import {
    PhotoRequestActorChange,
    PhotoRequestActorChangeFromJSON,
    PhotoRequestActorChangeFromJSONTyped,
    PhotoRequestActorChangeToJSON,
} from './PhotoRequestActorChange';
import {
    PhotoRequestItemReviewStatusChange,
    PhotoRequestItemReviewStatusChangeFromJSON,
    PhotoRequestItemReviewStatusChangeFromJSONTyped,
    PhotoRequestItemReviewStatusChangeToJSON,
} from './PhotoRequestItemReviewStatusChange';
import {
    PhotoRequestPhotoLinkChange,
    PhotoRequestPhotoLinkChangeFromJSON,
    PhotoRequestPhotoLinkChangeFromJSONTyped,
    PhotoRequestPhotoLinkChangeToJSON,
} from './PhotoRequestPhotoLinkChange';
import {
    PhotoRequestStatus,
    PhotoRequestStatusFromJSON,
    PhotoRequestStatusFromJSONTyped,
    PhotoRequestStatusToJSON,
} from './PhotoRequestStatus';
import {
    UpdatePhotoRequestActorData,
    UpdatePhotoRequestActorDataFromJSON,
    UpdatePhotoRequestActorDataFromJSONTyped,
    UpdatePhotoRequestActorDataToJSON,
} from './UpdatePhotoRequestActorData';
import {
    UpdatePhotoRequestPhotoLinkData,
    UpdatePhotoRequestPhotoLinkDataFromJSON,
    UpdatePhotoRequestPhotoLinkDataFromJSONTyped,
    UpdatePhotoRequestPhotoLinkDataToJSON,
} from './UpdatePhotoRequestPhotoLinkData';
import {
    UpdatePhotoRequestStatusData,
    UpdatePhotoRequestStatusDataFromJSON,
    UpdatePhotoRequestStatusDataFromJSONTyped,
    UpdatePhotoRequestStatusDataToJSON,
} from './UpdatePhotoRequestStatusData';

/**
 * 
 * @export
 * @interface PhotoRequestEventData
 */
export interface PhotoRequestEventData {
    /**
     * 
     * @type {PhotoRequestStatus}
     * @memberof PhotoRequestEventData
     */
    status: PhotoRequestStatus;
    /**
     * 
     * @type {PhotoRequestActorChange}
     * @memberof PhotoRequestEventData
     */
    actorChange: PhotoRequestActorChange;
    /**
     * 
     * @type {PhotoRequestPhotoLinkChange}
     * @memberof PhotoRequestEventData
     */
    photoLinkChange: PhotoRequestPhotoLinkChange;
    /**
     * 
     * @type {PhotoRequestActorAdditionOrRemoval}
     * @memberof PhotoRequestEventData
     */
    actorAdditionOrRemoval: PhotoRequestActorAdditionOrRemoval;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestEventData
     */
    skuID: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestEventData
     */
    requestItemId: number;
    /**
     * 
     * @type {PhotoRequestItemReviewStatusChange}
     * @memberof PhotoRequestEventData
     */
    reviewStatusChange: PhotoRequestItemReviewStatusChange;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestEventData
     */
    requestId: number;
    /**
     * 
     * @type {PaymentRate}
     * @memberof PhotoRequestEventData
     */
    old: PaymentRate;
    /**
     * 
     * @type {PaymentRate}
     * @memberof PhotoRequestEventData
     */
    _new: PaymentRate;
}

export function PhotoRequestEventDataFromJSON(json: any): PhotoRequestEventData {
    return PhotoRequestEventDataFromJSONTyped(json, false);
}

export function PhotoRequestEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestEventData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': PhotoRequestStatusFromJSON(json['status']),
        'actorChange': PhotoRequestActorChangeFromJSON(json['actorChange']),
        'photoLinkChange': PhotoRequestPhotoLinkChangeFromJSON(json['photoLinkChange']),
        'actorAdditionOrRemoval': PhotoRequestActorAdditionOrRemovalFromJSON(json['actorAdditionOrRemoval']),
        'skuID': json['skuID'],
        'requestItemId': json['requestItemId'],
        'reviewStatusChange': PhotoRequestItemReviewStatusChangeFromJSON(json['reviewStatusChange']),
        'requestId': json['requestId'],
        'old': PaymentRateFromJSON(json['old']),
        '_new': PaymentRateFromJSON(json['new']),
    };
}

export function PhotoRequestEventDataToJSON(value?: PhotoRequestEventData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': PhotoRequestStatusToJSON(value.status),
        'actorChange': PhotoRequestActorChangeToJSON(value.actorChange),
        'photoLinkChange': PhotoRequestPhotoLinkChangeToJSON(value.photoLinkChange),
        'actorAdditionOrRemoval': PhotoRequestActorAdditionOrRemovalToJSON(value.actorAdditionOrRemoval),
        'skuID': value.skuID,
        'requestItemId': value.requestItemId,
        'reviewStatusChange': PhotoRequestItemReviewStatusChangeToJSON(value.reviewStatusChange),
        'requestId': value.requestId,
        'old': PaymentRateToJSON(value.old),
        'new': PaymentRateToJSON(value._new),
    };
}

