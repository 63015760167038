/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateStudioImage
 */
export interface UpdateStudioImage {
    /**
     * 
     * @type {string}
     * @memberof UpdateStudioImage
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateStudioImage
     */
    ordering: number;
}

export function UpdateStudioImageFromJSON(json: any): UpdateStudioImage {
    return UpdateStudioImageFromJSONTyped(json, false);
}

export function UpdateStudioImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStudioImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': json['path'],
        'ordering': json['ordering'],
    };
}

export function UpdateStudioImageToJSON(value?: UpdateStudioImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': value.path,
        'ordering': value.ordering,
    };
}

