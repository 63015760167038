/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaType,
    MediaTypeFromJSON,
    MediaTypeFromJSONTyped,
    MediaTypeToJSON,
} from './MediaType';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface UpdateCategoryRequest
 */
export interface UpdateCategoryRequest {
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof UpdateCategoryRequest
     */
    shootingTypes: Array<ShootingType>;
    /**
     * 
     * @type {MediaType}
     * @memberof UpdateCategoryRequest
     */
    mediaType: MediaType;
}

export function UpdateCategoryRequestFromJSON(json: any): UpdateCategoryRequest {
    return UpdateCategoryRequestFromJSONTyped(json, false);
}

export function UpdateCategoryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCategoryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'mediaType': MediaTypeFromJSON(json['mediaType']),
    };
}

export function UpdateCategoryRequestToJSON(value?: UpdateCategoryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'mediaType': MediaTypeToJSON(value.mediaType),
    };
}

