/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncCategory
 */
export interface SyncCategory {
    /**
     * 
     * @type {number}
     * @memberof SyncCategory
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SyncCategory
     */
    parentId?: number;
    /**
     * 
     * @type {string}
     * @memberof SyncCategory
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncCategory
     */
    title: string;
}

export function SyncCategoryFromJSON(json: any): SyncCategory {
    return SyncCategoryFromJSONTyped(json, false);
}

export function SyncCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'title': json['title'],
    };
}

export function SyncCategoryToJSON(value?: SyncCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'path': value.path,
        'title': value.title,
    };
}

