/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Defect
 */
export interface Defect {
    /**
     * 
     * @type {string}
     * @memberof Defect
     */
    reasonId: string;
    /**
     * 
     * @type {string}
     * @memberof Defect
     */
    comment?: string;
}

export function DefectFromJSON(json: any): Defect {
    return DefectFromJSONTyped(json, false);
}

export function DefectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Defect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reasonId': json['reasonId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function DefectToJSON(value?: Defect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reasonId': value.reasonId,
        'comment': value.comment,
    };
}

