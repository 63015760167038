<template>
  <UiModal
    v-model:open="isVisible"
    centered
    class="confirm-modal"
    :after-close="afterCloseCb"
    @close="handleClose"
  >
    <div class="container" data-test-id="modal__confirm">
      <div class="header">
        <ExclamationCircleFilled
          v-if="type === 'info'"
          class="text-[24px] text-gold-6"
        />
        <CloseCircleFilled v-else class="text-[24px] text-red-5" />
        <div><slot name="header" /></div>
      </div>

      <div class="body">
        <div class="mt-1 ml-10"><slot /></div>

        <div class="actions">
          <UiButton
            v-if="!hideCancelButton"
            type="link"
            size="small"
            data-test-id="button__cancel"
            @click="handleClose"
          >
            {{ $t('button.cancel') }}
          </UiButton>

          <UiButton
            type="primary"
            size="small"
            data-test-id="button__confirm"
            @click="handleConfirm"
          >
            <slot name="confirm" />
          </UiButton>
        </div>
      </div>
    </div>
    <div
      data-test-id="modal-mask-close"
      class="absolute bottom-0 left-0 bg-transparent h-[1px] w-[1px]"
      @click="handleClose"
    />
  </UiModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({ name: 'confirm-modal' });
</script>

<script setup lang="ts">
import {
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons-vue';

import { UiButton, UiModal } from '@/ui';

const isVisible = ref(true);
const afterCloseEvent = ref<'confirm' | 'abort'>('abort');

interface IProps {
  hideCancelButton?: boolean;
  type?: 'error' | 'info';
}

withDefaults(defineProps<IProps>(), {
  type: 'info',
});

const emit = defineEmits<{
  (e: 'confirm'): void;
  (e: 'abort'): void;
}>();

const handleClose = () => {
  isVisible.value = false;
};

const handleConfirm = () => {
  afterCloseEvent.value = 'confirm';
  handleClose();
};

const afterCloseCb = () => {
  emit(afterCloseEvent.value);
};
</script>

<style lang="stylus" scoped>
.container
  font-size 14px

.header
  display flex
  column-gap 16px
  font-size 16px

.actions
  display flex
  gap 8px
  justify-content flex-end
  max-width 70%
  margin-top 16px
  margin-left auto
</style>
