/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CancelReservationRequest,
    CancelReservationRequestFromJSON,
    CancelReservationRequestToJSON,
    Reservation,
    ReservationFromJSON,
    ReservationToJSON,
    ReservationDetailedWithItems,
    ReservationDetailedWithItemsFromJSON,
    ReservationDetailedWithItemsToJSON,
    ReservationInfoListResponse,
    ReservationInfoListResponseFromJSON,
    ReservationInfoListResponseToJSON,
    ReservationStatus,
    ReservationStatusFromJSON,
    ReservationStatusToJSON,
    ReservationTemplateV1,
    ReservationTemplateV1FromJSON,
    ReservationTemplateV1ToJSON,
    ReservationTemplateV2,
    ReservationTemplateV2FromJSON,
    ReservationTemplateV2ToJSON,
    ReservationWithValidateError,
    ReservationWithValidateErrorFromJSON,
    ReservationWithValidateErrorToJSON,
    ResponseError,
    ResponseErrorFromJSON,
    ResponseErrorToJSON,
    ShipmentTemplate,
    ShipmentTemplateFromJSON,
    ShipmentTemplateToJSON,
    SortByDate,
    SortByDateFromJSON,
    SortByDateToJSON,
    UpdateReservationItemsStatus,
    UpdateReservationItemsStatusFromJSON,
    UpdateReservationItemsStatusToJSON,
} from '../models';

export interface CreateReservationRequest {
    reservationTemplateV2?: ReservationTemplateV2;
}

export interface GetReservationDetailedRequest {
    reservationID: string;
    page: number;
    size: number;
    sort?: Array<SortByDate>;
}

export interface GetReservationsRequest {
    page: number;
    size: number;
    mine?: boolean;
    reservationStatuses?: Array<ReservationStatus>;
    sort?: Array<SortByDate>;
}

export interface InternalCreateReservationV1Request {
    reservationTemplateV1?: ReservationTemplateV1;
}

export interface InternalDeleteReservationRequest {
    reservationID: string;
}

export interface InternalReserveOnWMSRequest {
    reservationID: string;
}

export interface InternalShipReservationRequest {
    shipmentTemplate?: ShipmentTemplate;
}

export interface InternalUpdateReservationItemsV2Request {
    updateReservationItemsStatus?: UpdateReservationItemsStatus;
}

export interface InternalV1CancelReservationRequest {
    reservationID: string;
    cancelReservationRequest?: CancelReservationRequest;
}

/**
 * 
 */
export class ReservationApi extends runtime.BaseAPI {

    /**
     * Зарезервировать список ску
     */
    async createReservationRaw(requestParameters: CreateReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationWithValidateError>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reservation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationTemplateV2ToJSON(requestParameters.reservationTemplateV2),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationWithValidateErrorFromJSON(jsonValue));
    }

    /**
     * Зарезервировать список ску
     */
    async createReservation(requestParameters: CreateReservationRequest = {}, initOverrides?: RequestInit): Promise<ReservationWithValidateError> {
        const response = await this.createReservationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить полную информацию о резервации
     */
    async getReservationDetailedRaw(requestParameters: GetReservationDetailedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationDetailedWithItems>> {
        if (requestParameters.reservationID === null || requestParameters.reservationID === undefined) {
            throw new runtime.RequiredError('reservationID','Required parameter requestParameters.reservationID was null or undefined when calling getReservationDetailed.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getReservationDetailed.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getReservationDetailed.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reservation/{reservationID}`.replace(`{${"reservationID"}}`, encodeURIComponent(String(requestParameters.reservationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationDetailedWithItemsFromJSON(jsonValue));
    }

    /**
     * Получить полную информацию о резервации
     */
    async getReservationDetailed(requestParameters: GetReservationDetailedRequest, initOverrides?: RequestInit): Promise<ReservationDetailedWithItems> {
        const response = await this.getReservationDetailedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получить список резерваций
     */
    async getReservationsRaw(requestParameters: GetReservationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationInfoListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getReservations.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getReservations.');
        }

        const queryParameters: any = {};

        if (requestParameters.mine !== undefined) {
            queryParameters['mine'] = requestParameters.mine;
        }

        if (requestParameters.reservationStatuses) {
            queryParameters['reservationStatuses'] = requestParameters.reservationStatuses;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/reservation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationInfoListResponseFromJSON(jsonValue));
    }

    /**
     * Получить список резерваций
     */
    async getReservations(requestParameters: GetReservationsRequest, initOverrides?: RequestInit): Promise<ReservationInfoListResponse> {
        const response = await this.getReservationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создать новую резервацию
     */
    async internalCreateReservationV1Raw(requestParameters: InternalCreateReservationV1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Reservation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/v1/reservation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationTemplateV1ToJSON(requestParameters.reservationTemplateV1),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     * Создать новую резервацию
     */
    async internalCreateReservationV1(requestParameters: InternalCreateReservationV1Request = {}, initOverrides?: RequestInit): Promise<Reservation> {
        const response = await this.internalCreateReservationV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Удалить резервацию
     */
    async internalDeleteReservationRaw(requestParameters: InternalDeleteReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationID === null || requestParameters.reservationID === undefined) {
            throw new runtime.RequiredError('reservationID','Required parameter requestParameters.reservationID was null or undefined when calling internalDeleteReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/reservation/{reservationID}`.replace(`{${"reservationID"}}`, encodeURIComponent(String(requestParameters.reservationID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удалить резервацию
     */
    async internalDeleteReservation(requestParameters: InternalDeleteReservationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalDeleteReservationRaw(requestParameters, initOverrides);
    }

    /**
     * Зарезервировать на WMS
     */
    async internalReserveOnWMSRaw(requestParameters: InternalReserveOnWMSRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationID === null || requestParameters.reservationID === undefined) {
            throw new runtime.RequiredError('reservationID','Required parameter requestParameters.reservationID was null or undefined when calling internalReserveOnWMS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/reservation/{reservationID}/reserveOnWms`.replace(`{${"reservationID"}}`, encodeURIComponent(String(requestParameters.reservationID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Зарезервировать на WMS
     */
    async internalReserveOnWMS(requestParameters: InternalReserveOnWMSRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalReserveOnWMSRaw(requestParameters, initOverrides);
    }

    /**
     * Создать новую отправку
     */
    async internalShipReservationRaw(requestParameters: InternalShipReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/reservation/ship`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShipmentTemplateToJSON(requestParameters.shipmentTemplate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Создать новую отправку
     */
    async internalShipReservation(requestParameters: InternalShipReservationRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.internalShipReservationRaw(requestParameters, initOverrides);
    }

    /**
     * Обновить статусы айтемов после ответа от вмс
     */
    async internalUpdateReservationItemsV2Raw(requestParameters: InternalUpdateReservationItemsV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/v1/reservation/items-status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateReservationItemsStatusToJSON(requestParameters.updateReservationItemsStatus),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Обновить статусы айтемов после ответа от вмс
     */
    async internalUpdateReservationItemsV2(requestParameters: InternalUpdateReservationItemsV2Request = {}, initOverrides?: RequestInit): Promise<void> {
        await this.internalUpdateReservationItemsV2Raw(requestParameters, initOverrides);
    }

    /**
     * Отменяет указанные в теле reservation items. Если там пусто, отменяет всю резервацию
     * Отменить всю или часть резервации
     */
    async internalV1CancelReservationRaw(requestParameters: InternalV1CancelReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationID === null || requestParameters.reservationID === undefined) {
            throw new runtime.RequiredError('reservationID','Required parameter requestParameters.reservationID was null or undefined when calling internalV1CancelReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/internal/v1/reservation/{reservationID}/cancel`.replace(`{${"reservationID"}}`, encodeURIComponent(String(requestParameters.reservationID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelReservationRequestToJSON(requestParameters.cancelReservationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Отменяет указанные в теле reservation items. Если там пусто, отменяет всю резервацию
     * Отменить всю или часть резервации
     */
    async internalV1CancelReservation(requestParameters: InternalV1CancelReservationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.internalV1CancelReservationRaw(requestParameters, initOverrides);
    }

}
