/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UpdateB2BImage,
    UpdateB2BImageFromJSON,
    UpdateB2BImageFromJSONTyped,
    UpdateB2BImageToJSON,
} from './UpdateB2BImage';
import {
    UpdateStudioImage,
    UpdateStudioImageFromJSON,
    UpdateStudioImageFromJSONTyped,
    UpdateStudioImageToJSON,
} from './UpdateStudioImage';

/**
 * Должен быть заполнен хотябы один массив.
 * @export
 * @interface UpdateMedia
 */
export interface UpdateMedia {
    /**
     * Обновление порядка временных фото. Ключи, которые не перечислены, будут УДАЛЕНЫ!!!
     * @type {Array<UpdateB2BImage>}
     * @memberof UpdateMedia
     */
    updateTempImages?: Array<UpdateB2BImage>;
    /**
     * Обновление порядка еще не загруженных фото.
     * @type {Array<UpdateStudioImage>}
     * @memberof UpdateMedia
     */
    updateStudioImages?: Array<UpdateStudioImage>;
}

export function UpdateMediaFromJSON(json: any): UpdateMedia {
    return UpdateMediaFromJSONTyped(json, false);
}

export function UpdateMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updateTempImages': !exists(json, 'updateTempImages') ? undefined : ((json['updateTempImages'] as Array<any>).map(UpdateB2BImageFromJSON)),
        'updateStudioImages': !exists(json, 'updateStudioImages') ? undefined : ((json['updateStudioImages'] as Array<any>).map(UpdateStudioImageFromJSON)),
    };
}

export function UpdateMediaToJSON(value?: UpdateMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updateTempImages': value.updateTempImages === undefined ? undefined : ((value.updateTempImages as Array<any>).map(UpdateB2BImageToJSON)),
        'updateStudioImages': value.updateStudioImages === undefined ? undefined : ((value.updateStudioImages as Array<any>).map(UpdateStudioImageToJSON)),
    };
}

