import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
  RouterView,
} from 'vue-router';

import { useAuth } from '@/features/Auth';
import { featureFlags } from '@/features/FeatureFlags';

import { AppRoutes, baseRoute } from './data';
import { applyGuards, handleError } from './guard';

const Administration = () => import('@/pages/administration/index.vue');
const Barcodes = () => import('@/pages/barcodes/index.vue');
const Complaints = () => import('@/pages/complaints/index.vue');
const Invoice = () => import('@/pages/invoice/index.vue');
const InvoiceCreation = () => import('@/pages/invoiceCreation/index.vue');
const Invoices = () => import('@/pages/invoices/index.vue');
const Products = () => import('@/pages/products/index.vue');
const Requests = () => import('@/pages/requests/index.vue');
const Request = () => import('@/pages/request/index.vue');
const Product = () => import('@/pages/sku/index.vue');
const Transfer = () => import('@/pages/transfer/index.vue');
const NotAuthorized = () => import('@/pages/notAuthorized/index.vue');
const Login = () => import('@/pages/login/index.vue');
const Reservations = () => import('@/pages/reservations/ReservationsPage.vue');
const Reservation = () => import('@/pages/reservation/ReservationPage.vue');
const ErrorPage = () => import('@/pages/error/index.vue');

const MultipleRequestCreation = () =>
  import('@/pages/multipleRequestCreation/index.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: RouterView,
    meta: {
      protected: true,
      layout: 'default',
    },
    redirect: { name: AppRoutes.invoices.name },
    children: [
      {
        ...AppRoutes.invoices,
        component: Invoices,
      },
      {
        ...AppRoutes.invoiceCreation,
        component: InvoiceCreation,
      },
      {
        ...AppRoutes.invoice,
        component: Invoice,
        meta: {
          parent: AppRoutes.invoices,
        },
      },
      {
        ...AppRoutes.requests,
        component: Requests,
        meta: {
          parent: AppRoutes.requests,
        },
      },
      {
        ...AppRoutes.request,
        component: Request,
        meta: {
          parent: AppRoutes.requests,
        },
        beforeEnter: (e) => {
          if (!featureFlags.redesignedPhotorequests)
            return {
              name: AppRoutes.requests.name,
              params: { id: e.params.id },
            };
        },
      },
      {
        ...AppRoutes.products,
        component: Products,
      },
      {
        ...AppRoutes.product,
        component: Product,
        meta: {
          parent: AppRoutes.products,
        },
      },
      {
        ...AppRoutes.transfer,
        component: Transfer,
      },
      {
        ...AppRoutes.barcodes,
        component: Barcodes,
      },
      {
        ...AppRoutes.error,
        component: ErrorPage,
        beforeEnter: (e) => {
          const status = Number(e?.params?.status ?? 0);
          const allowedStatuses = [403, 404, 500];

          if (!allowedStatuses.includes(status)) {
            return { ...e, params: { status: 404 } };
          }

          return true;
        },
      },
      {
        ...AppRoutes.complaints,
        component: Complaints,
      },
      {
        ...AppRoutes.reservations,
        component: Reservations,
        beforeEnter: () => {
          const { canViewReservations } = useAuth();

          if (!canViewReservations.value) return { path: '/' };
        },
      },
      {
        ...AppRoutes.reservation,
        component: Reservation,
        meta: {
          parent: AppRoutes.reservations,
        },
        beforeEnter: () => {
          const { canViewReservations } = useAuth();

          if (!canViewReservations.value) return { path: '/' };
        },
      },
      {
        ...AppRoutes.administration,
        component: Administration,
        beforeEnter: () => {
          const { isPhotostudioAdmin } = useAuth();

          if (!isPhotostudioAdmin.value) return { path: '/' };
        },
      },
      {
        ...AppRoutes.multipleRequestCreation,
        component: MultipleRequestCreation,
        beforeEnter: (e) => {
          if (!featureFlags.multiplyRequestCreation)
            return { name: AppRoutes.invoices.name };
        },
      },
    ],
  },
  {
    ...AppRoutes.login,
    component: Login,
    meta: {
      layout: 'centered',
    },
  },
  {
    ...AppRoutes.notAuthorized,
    component: NotAuthorized,
    meta: {
      layout: 'centered',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: AppRoutes.invoices.name },
  },
];

export let router: Router;

export const routerFactory = (base?: string): Router => {
  router = createRouter({
    history: createWebHistory(base),
    routes,
  });
  applyGuards(router);
  handleError(router);

  return router;
};

export { AppRoutes, baseRoute };

declare module 'vue-router' {
  interface RouteMeta {
    parent?: (typeof AppRoutes)[keyof typeof AppRoutes];
  }
}
